<template>
  <div class="payment-status" v-if="status">
    <div class="container">
      <base-notification-box
        :heading="notificationHeading"
        :text="notificationText"
        :mode="status"
      >
        <base-button to="/my-courses">
          {{ $t("paymentStatus.button") }}
        </base-button>
      </base-notification-box>
    </div>
  </div>
</template>

<script>
import payment from '@/api/services/payment';
import { mapState } from 'vuex';

export default {
  name: 'PaymentStatus',
  data: () => ({
    status: false,
  }),
  computed: {
    ...mapState({
      cartList: (state) => state.cart.cartList,
    }),
    notificationHeading() {
      return `<span>
      ${this.$t('paymentStatus.payment')}:
        <strong class=${`base-notification-box__status--${this.status}`}>
          ${this.$t(`paymentStatus.${this.status}.heading`)}
        </strong>
      </span>`;
    },
    notificationText() {
      return this.$t(`paymentStatus.${this.status}.text`);
    },
  },
  async created() {
    const token = this.$route.query ? this.$route.query.token : null;
    this.$store.dispatch('ui/setPreloader', true);

    try {
      await payment.confirmPayment(token);

      const value = this.cartList.reduce(
        (accumulator, item) => accumulator + item.price / 100,
        0,
      );

      this.$analytics.fbq.event('Purchase', { value, currency: 'EUR' });

      this.status = 'success';
      this.$store.dispatch('cart/clearCart');
    } catch (err) {
      console.log(err);
      this.status = 'error';

      this.$store.dispatch('notification/spawn', {
        errorMessage: err.response.data.message,
        type: 'error',
      });
    } finally {
      this.$store.dispatch('ui/setPreloader', false);
    }
  },
};
</script>

<style lang="scss" scoped src="./PaymentStatus.scss" />
